.introCont {
    /* background-color:#FCD581; */
    background-color: #990D35;
    height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
}

.infoCont {
    top: 20%;
    /* left: 10%; */
    position: fixed;
    text-align: left;
    /* background-color: #D52941; */
    padding: 20px;
    width: 100%;
    color: white;

}

.row {

}

.yellowText {
    color: #FCD581;
}

.mmImage {
    width: 30vh;
    height: auto;
    border-radius: 2%;
    box-shadow: #222 0.2em 0.2em 1em;
    
    -webkit-filter: grayscale(100%) 
            brightness(135%)
            contrast(120%);
    filter: grayscale(100%) 
            brightness(135%)
            contrast(120%);
    
    transition: filter 0.3s, box-shadow 0.3s;
    -webkit-transition: filter 0.3s, -webkit-filter 0.3s, box-shadow 0.3s;
    margin: auto;
    
}

  .mmImage:hover , 
  .mmImage:focus  {
    -webkit-filter: none;
    filter: none;
    box-shadow: #224 0.2em 0.2em 0.6em 0.1em;
  }

.mmInfo {
    margin: auto;

}

.mmName {
    font-size: 60px;
}

.cheesyTitle {
    color: #FFF8E8;
}

.shortIntro {
    
}